import React, { FunctionComponent } from "react";

interface IProps {
  width: string;
  height: string;
}

const SearchIcon: FunctionComponent<IProps> = ({ width, height }) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 43 43`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon
        id="path-1"
        points={`0 3.53909465e-05 42.9252136 3.53909465e-05 42.9252136 43 0 43`}
      ></polygon>
    </defs>
    <g id="Layout" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Home" transform="translate(-912.000000, -292.000000)">
        <g id="Group-7" transform="translate(729.000000, 248.000000)">
          <g id="Group-4" transform="translate(111.000000, 44.000000)">
            <g id="Group" transform="translate(72.000000, 0.000000)">
              <g id="Group-3">
                <mask id="mask-2" fill="currentColor">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Clip-2"></g>
                <path
                  d="M37.7624234,39.9535827 L29.5360908,31.7104119 L31.6920066,29.6178337 L39.88514,37.8265868 L37.7624234,39.9535827 Z M2.51866982,17.9012185 C2.51866982,9.42199012 9.40264312,2.52385226 17.8644604,2.52385226 C26.3262776,2.52385226 33.2107807,9.42199012 33.2107807,17.9012185 C33.2107807,26.3804469 26.3262776,33.2791156 17.8644604,33.2791156 C9.40264312,33.2791156 2.51866982,26.3804469 2.51866982,17.9012185 L2.51866982,17.9012185 Z M42.5562612,36.9343811 L33.0155589,27.3751979 C34.7341462,24.6254984 35.7295918,21.3778481 35.7295918,17.9012185 C35.7295918,8.03068354 27.7147273,3.53909465e-05 17.8644604,3.53909465e-05 C8.01410513,3.53909465e-05 -5.29774127e-05,8.03068354 -5.29774127e-05,17.9012185 C-5.29774127e-05,27.7717535 8.01410513,35.803021 17.8644604,35.803021 C21.3406616,35.803021 24.5874706,34.8017226 27.3348793,33.0734942 L36.8720497,42.6302885 C37.1179532,42.8767864 37.4402324,43.0000354 37.7624234,43.0000354 C38.0846144,43.0000354 38.4068936,42.8767864 38.6527971,42.6302885 L42.5562612,38.7187926 C42.7924522,38.4822041 42.9252489,38.1617391 42.9252489,37.8265868 C42.9252489,37.4913461 42.7924522,37.1709695 42.5562612,36.9343811 L42.5562612,36.9343811 Z"
                  id="Fill-1"
                  fill="currentColor"
                  mask="url(#mask-2)"
                ></path>
              </g>
              <path
                d="M29.7398646,21 C30.4364156,21 31,20.4357088 31,19.7398646 C31,11.6125523 24.3880661,5 16.260047,5 C15.5642028,5 15,5.56420281 15,6.2601354 C15,6.95597964 15.5642028,7.52018244 16.260047,7.52018244 C22.9980563,7.52018244 28.4797292,13.0018553 28.4797292,19.7398646 C28.4797292,20.4357088 29.0433136,21 29.7398646,21"
                id="Fill-4"
                fill="currentColor"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SearchIcon;
