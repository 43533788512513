export const admissionDTO = {
  id: 202,
  admitted: 1,
  type: "N",
  ward: {
    code: "I",
    description: "MALE WARDS",
    telephone: "210",
    fax: "",
    email: "",
    beds: 53,
    nurs: 14,
    docs: 1,
    lock: 1,
    pharmacy: true,
    male: true,
    female: true,
  },
  yProg: 2,
  patient: {
    code: 1,
    firstName: "Mario",
    secondName: "Rossia",
    name: "Mario Rossia",
    birthDate: "1975-03-02T14:58:00.000Z",
    age: 0,
    agetype: "d4",
    sex: "M",
    address: "Via Roma, 12",
    city: "Verona",
    nextKin: null,
    telephone: "+393456789012",
    note: "Test insert new patient",
    motherName: "Roberta",
    mother: "A",
    fatherName: "Giuseppe",
    father: "D",
    bloodType: "A+",
    hasInsurance: "N",
    parentTogether: "N",
    taxCode: "RSSMRA79E01L781N",
    lock: 1,
    blobPhoto: "",
    hashCode: 0,
  },
  admDate: "2021-08-27T10:19:44.000Z",
  admType: {
    code: "I",
    description: "SELF",
  },
  diseaseIn: {
    code: 88,
    description: "Diarrhoea-Dysentry",
    diseaseType: {
      code: "ND",
      description: "1.NOTIFIABLE DISEASES",
    },
    opdInclude: true,
    ipdInInclude: true,
    ipdOutInclude: true,
    lock: 2,
  },
  diseaseOut: null,
  operation: null,
  opDate: null,
  opResult: null,
  disDate: null,
  disType: null,
  transUnit: 0,
  visitDate: null,
  pregTreatmentType: null,
  deliveryDate: null,
  deliveryType: null,
  deliveryResult: null,
  weight: null,
  ctrlDate1: null,
  ctrlDate2: null,
  abortDate: null,
  userID: "admin",
  lock: 0,
  deleted: "N",
  fhu: null,
  note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempor ac ipsum id luctus. Quisque fermentum commodo felis nec fermentum. In ut volutpat ligula. Praesent condimentum consequat urna vel accumsan. Cras venenatis nisi in ipsum malesuada malesuada. Donec dictum et mauris sed dapibus. Praesent metus dui, faucibus et rhoncus ut, vulputate ac massa. Donec sagittis massa nisl, et eleifend enim pharetra eu. Ut in lobortis enim. Duis in purus lectus. Morbi consectetur nisi vel rutrum porta. Fusce in vehicula justo. Duis scelerisque sem magna, eu facilisis ante gravida in. \nNam vel elit convallis, condimentum enim cursus, dapibus ligula.Phasellus in est in est interdum congue.Maecenas in egestas felis.Nunc lacinia urna ac enim varius hendrerit.Nam erat diam, fermentum vitae vulputate vel, tincidunt sed quam.Aliquam vehicula urna in maximus varius.Vivamus dignissim ipsum et laoreet eleifend.Suspendisse potenti.Sed at fermentum augue.Suspendisse cursus purus ut orci scelerisque, non tempor sem dignissim.Suspendisse potenti.Nunc consequat, tellus ac tempor aliquet, dui diam fringilla orci, vitae faucibus nunc orci rhoncus mauris.Vestibulum faucibus sapien id posuere maximus. \nDonec vehicula risus viverra facilisis commodo.Donec ultrices justo et lacus convallis convallis vel sed libero.Phasellus pellentesque faucibus vestibulum.Mauris ultricies.",
};
