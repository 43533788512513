// tslint:disable
/**
 * OH 2.0 Api Documentation
 * OH 2.0 Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    PriceDTO,
    PriceListDTO,
} from '../models';

export interface CopyByFactorAndStepRequest {
    id: number;
    factor: number;
    step: number;
}

export interface CopyListRequest {
    id: number;
}

export interface DeletePriceListRequest {
    id: number;
}

export interface NewPriceListRequest {
    priceListDTO: PriceListDTO;
}

export interface UpdatePriceListsRequest {
    id: number;
    priceListDTO: PriceListDTO;
}

/**
 * no description
 */
export class PriceListsApi extends BaseAPI {

    /**
     */
    copyByFactorAndStep({ id, factor, step }: CopyByFactorAndStepRequest): Observable<boolean>
    copyByFactorAndStep({ id, factor, step }: CopyByFactorAndStepRequest, opts?: OperationOpts): Observable<RawAjaxResponse<boolean>>
    copyByFactorAndStep({ id, factor, step }: CopyByFactorAndStepRequest, opts?: OperationOpts): Observable<boolean | RawAjaxResponse<boolean>> {
        throwIfNullOrUndefined(id, 'id', 'copyByFactorAndStep');
        throwIfNullOrUndefined(factor, 'factor', 'copyByFactorAndStep');
        throwIfNullOrUndefined(step, 'step', 'copyByFactorAndStep');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'factor': factor,
            'step': step,
        };

        return this.request<boolean>({
            url: '/pricelists/duplicate/byfactor/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    copyList({ id }: CopyListRequest): Observable<boolean>
    copyList({ id }: CopyListRequest, opts?: OperationOpts): Observable<RawAjaxResponse<boolean>>
    copyList({ id }: CopyListRequest, opts?: OperationOpts): Observable<boolean | RawAjaxResponse<boolean>> {
        throwIfNullOrUndefined(id, 'id', 'copyList');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<boolean>({
            url: '/pricelists/duplicate/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    deletePriceList({ id }: DeletePriceListRequest): Observable<boolean>
    deletePriceList({ id }: DeletePriceListRequest, opts?: OperationOpts): Observable<RawAjaxResponse<boolean>>
    deletePriceList({ id }: DeletePriceListRequest, opts?: OperationOpts): Observable<boolean | RawAjaxResponse<boolean>> {
        throwIfNullOrUndefined(id, 'id', 'deletePriceList');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<boolean>({
            url: '/pricelists/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getPriceLists(): Observable<Array<PriceListDTO>>
    getPriceLists(opts?: OperationOpts): Observable<RawAjaxResponse<Array<PriceListDTO>>>
    getPriceLists(opts?: OperationOpts): Observable<Array<PriceListDTO> | RawAjaxResponse<Array<PriceListDTO>>> {
        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<PriceListDTO>>({
            url: '/pricelists',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getPrices(): Observable<Array<PriceDTO>>
    getPrices(opts?: OperationOpts): Observable<RawAjaxResponse<Array<PriceDTO>>>
    getPrices(opts?: OperationOpts): Observable<Array<PriceDTO> | RawAjaxResponse<Array<PriceDTO>>> {
        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<PriceDTO>>({
            url: '/pricelists/prices',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    newPriceList({ priceListDTO }: NewPriceListRequest): Observable<PriceListDTO>
    newPriceList({ priceListDTO }: NewPriceListRequest, opts?: OperationOpts): Observable<RawAjaxResponse<PriceListDTO>>
    newPriceList({ priceListDTO }: NewPriceListRequest, opts?: OperationOpts): Observable<PriceListDTO | RawAjaxResponse<PriceListDTO>> {
        throwIfNullOrUndefined(priceListDTO, 'priceListDTO', 'newPriceList');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<PriceListDTO>({
            url: '/pricelists',
            method: 'POST',
            headers,
            body: priceListDTO,
        }, opts?.responseOpts);
    };

    /**
     */
    updatePriceLists({ id, priceListDTO }: UpdatePriceListsRequest): Observable<PriceListDTO>
    updatePriceLists({ id, priceListDTO }: UpdatePriceListsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<PriceListDTO>>
    updatePriceLists({ id, priceListDTO }: UpdatePriceListsRequest, opts?: OperationOpts): Observable<PriceListDTO | RawAjaxResponse<PriceListDTO>> {
        throwIfNullOrUndefined(id, 'id', 'updatePriceLists');
        throwIfNullOrUndefined(priceListDTO, 'priceListDTO', 'updatePriceLists');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<PriceListDTO>({
            url: '/pricelists/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: priceListDTO,
        }, opts?.responseOpts);
    };

}
