// tslint:disable
/**
 * OH 2.0 Api Documentation
 * OH 2.0 Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    VaccineTypeDTO,
} from '../models';

export interface CheckVaccineTypeCodeRequest {
    code: string;
}

export interface DeleteVaccineTypeRequest {
    code: string;
}

export interface NewVaccineTypeRequest {
    vaccineTypeDTO: VaccineTypeDTO;
}

export interface UpdateVaccineTypeRequest {
    vaccineTypeDTO: VaccineTypeDTO;
}

/**
 * no description
 */
export class VaccineTypeApi extends BaseAPI {

    /**
     */
    checkVaccineTypeCode({ code }: CheckVaccineTypeCodeRequest): Observable<boolean>
    checkVaccineTypeCode({ code }: CheckVaccineTypeCodeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<boolean>>
    checkVaccineTypeCode({ code }: CheckVaccineTypeCodeRequest, opts?: OperationOpts): Observable<boolean | RawAjaxResponse<boolean>> {
        throwIfNullOrUndefined(code, 'code', 'checkVaccineTypeCode');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<boolean>({
            url: '/vaccinetypes/check/{code}'.replace('{code}', encodeURI(code)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    deleteVaccineType({ code }: DeleteVaccineTypeRequest): Observable<boolean>
    deleteVaccineType({ code }: DeleteVaccineTypeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<boolean>>
    deleteVaccineType({ code }: DeleteVaccineTypeRequest, opts?: OperationOpts): Observable<boolean | RawAjaxResponse<boolean>> {
        throwIfNullOrUndefined(code, 'code', 'deleteVaccineType');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<boolean>({
            url: '/vaccinetypes/{code}'.replace('{code}', encodeURI(code)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getVaccineType(): Observable<Array<VaccineTypeDTO>>
    getVaccineType(opts?: OperationOpts): Observable<RawAjaxResponse<Array<VaccineTypeDTO>>>
    getVaccineType(opts?: OperationOpts): Observable<Array<VaccineTypeDTO> | RawAjaxResponse<Array<VaccineTypeDTO>>> {
        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<VaccineTypeDTO>>({
            url: '/vaccinetypes',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    newVaccineType({ vaccineTypeDTO }: NewVaccineTypeRequest): Observable<VaccineTypeDTO>
    newVaccineType({ vaccineTypeDTO }: NewVaccineTypeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<VaccineTypeDTO>>
    newVaccineType({ vaccineTypeDTO }: NewVaccineTypeRequest, opts?: OperationOpts): Observable<VaccineTypeDTO | RawAjaxResponse<VaccineTypeDTO>> {
        throwIfNullOrUndefined(vaccineTypeDTO, 'vaccineTypeDTO', 'newVaccineType');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<VaccineTypeDTO>({
            url: '/vaccinetypes',
            method: 'POST',
            headers,
            body: vaccineTypeDTO,
        }, opts?.responseOpts);
    };

    /**
     */
    updateVaccineType({ vaccineTypeDTO }: UpdateVaccineTypeRequest): Observable<VaccineTypeDTO>
    updateVaccineType({ vaccineTypeDTO }: UpdateVaccineTypeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<VaccineTypeDTO>>
    updateVaccineType({ vaccineTypeDTO }: UpdateVaccineTypeRequest, opts?: OperationOpts): Observable<VaccineTypeDTO | RawAjaxResponse<VaccineTypeDTO>> {
        throwIfNullOrUndefined(vaccineTypeDTO, 'vaccineTypeDTO', 'updateVaccineType');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<VaccineTypeDTO>({
            url: '/vaccinetypes',
            method: 'PUT',
            headers,
            body: vaccineTypeDTO,
        }, opts?.responseOpts);
    };

}
