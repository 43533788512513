// tslint:disable
/**
 * OH 2.0 Api Documentation
 * OH 2.0 Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    BillDTO,
    BillItemsDTO,
    BillPaymentsDTO,
    FullBillDTO,
} from '../models';

export interface DeleteBillRequest {
    id: number;
}

export interface GetBillRequest {
    id: number;
}

export interface GetItemsRequest {
    billId: number;
}

export interface GetPaymentsByBillIdRequest {
    billId: number;
}

export interface GetPendingBillsRequest {
    patientCode: number;
}

export interface GetPendingBillsAffiliateRequest {
    patientCode: number;
}

export interface NewBillRequest {
    fullBillDTO: FullBillDTO;
}

export interface SearchBillsRequest {
    datefrom: string;
    dateto: string;
    billItemsDTO: BillItemsDTO;
}

export interface SearchBills1Request {
    datefrom: string;
    dateto: string;
    patientCode?: number;
}

export interface SearchBillsByPaymentsRequest {
    billPaymentsDTO: Array<BillPaymentsDTO>;
}

export interface SearchBillsPaymentsRequest {
    datefrom: string;
    dateto: string;
    patientCode?: number;
}

export interface UpdateBillRequest {
    id: number;
    fullBillDTO: FullBillDTO;
}

/**
 * no description
 */
export class BillsApi extends BaseAPI {

    /**
     */
    deleteBill({ id }: DeleteBillRequest): Observable<boolean>
    deleteBill({ id }: DeleteBillRequest, opts?: OperationOpts): Observable<RawAjaxResponse<boolean>>
    deleteBill({ id }: DeleteBillRequest, opts?: OperationOpts): Observable<boolean | RawAjaxResponse<boolean>> {
        throwIfNullOrUndefined(id, 'id', 'deleteBill');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<boolean>({
            url: '/bills/{id}'.replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getBill({ id }: GetBillRequest): Observable<BillDTO>
    getBill({ id }: GetBillRequest, opts?: OperationOpts): Observable<RawAjaxResponse<BillDTO>>
    getBill({ id }: GetBillRequest, opts?: OperationOpts): Observable<BillDTO | RawAjaxResponse<BillDTO>> {
        throwIfNullOrUndefined(id, 'id', 'getBill');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<BillDTO>({
            url: '/bills/{id}'.replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getDistinctItems(): Observable<Array<BillItemsDTO>>
    getDistinctItems(opts?: OperationOpts): Observable<RawAjaxResponse<Array<BillItemsDTO>>>
    getDistinctItems(opts?: OperationOpts): Observable<Array<BillItemsDTO> | RawAjaxResponse<Array<BillItemsDTO>>> {
        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<BillItemsDTO>>({
            url: '/bills/items',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getItems({ billId }: GetItemsRequest): Observable<Array<BillItemsDTO>>
    getItems({ billId }: GetItemsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<BillItemsDTO>>>
    getItems({ billId }: GetItemsRequest, opts?: OperationOpts): Observable<Array<BillItemsDTO> | RawAjaxResponse<Array<BillItemsDTO>>> {
        throwIfNullOrUndefined(billId, 'billId', 'getItems');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<BillItemsDTO>>({
            url: '/bills/items/{bill_id}'.replace('{bill_id}', encodeURI(billId)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getPaymentsByBillId({ billId }: GetPaymentsByBillIdRequest): Observable<Array<BillPaymentsDTO>>
    getPaymentsByBillId({ billId }: GetPaymentsByBillIdRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<BillPaymentsDTO>>>
    getPaymentsByBillId({ billId }: GetPaymentsByBillIdRequest, opts?: OperationOpts): Observable<Array<BillPaymentsDTO> | RawAjaxResponse<Array<BillPaymentsDTO>>> {
        throwIfNullOrUndefined(billId, 'billId', 'getPaymentsByBillId');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<BillPaymentsDTO>>({
            url: '/bills/payments/{bill_id}'.replace('{bill_id}', encodeURI(billId)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getPendingBills({ patientCode }: GetPendingBillsRequest): Observable<Array<BillDTO>>
    getPendingBills({ patientCode }: GetPendingBillsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<BillDTO>>>
    getPendingBills({ patientCode }: GetPendingBillsRequest, opts?: OperationOpts): Observable<Array<BillDTO> | RawAjaxResponse<Array<BillDTO>>> {
        throwIfNullOrUndefined(patientCode, 'patientCode', 'getPendingBills');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'patient_code': patientCode,
        };

        return this.request<Array<BillDTO>>({
            url: '/bills/pending',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getPendingBillsAffiliate({ patientCode }: GetPendingBillsAffiliateRequest): Observable<Array<BillDTO>>
    getPendingBillsAffiliate({ patientCode }: GetPendingBillsAffiliateRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<BillDTO>>>
    getPendingBillsAffiliate({ patientCode }: GetPendingBillsAffiliateRequest, opts?: OperationOpts): Observable<Array<BillDTO> | RawAjaxResponse<Array<BillDTO>>> {
        throwIfNullOrUndefined(patientCode, 'patientCode', 'getPendingBillsAffiliate');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'patient_code': patientCode,
        };

        return this.request<Array<BillDTO>>({
            url: '/bills/pending/affiliate',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    newBill({ fullBillDTO }: NewBillRequest): Observable<FullBillDTO>
    newBill({ fullBillDTO }: NewBillRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FullBillDTO>>
    newBill({ fullBillDTO }: NewBillRequest, opts?: OperationOpts): Observable<FullBillDTO | RawAjaxResponse<FullBillDTO>> {
        throwIfNullOrUndefined(fullBillDTO, 'fullBillDTO', 'newBill');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<FullBillDTO>({
            url: '/bills',
            method: 'POST',
            headers,
            body: fullBillDTO,
        }, opts?.responseOpts);
    };

    /**
     */
    searchBills({ datefrom, dateto, billItemsDTO }: SearchBillsRequest): Observable<Array<BillDTO>>
    searchBills({ datefrom, dateto, billItemsDTO }: SearchBillsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<BillDTO>>>
    searchBills({ datefrom, dateto, billItemsDTO }: SearchBillsRequest, opts?: OperationOpts): Observable<Array<BillDTO> | RawAjaxResponse<Array<BillDTO>>> {
        throwIfNullOrUndefined(datefrom, 'datefrom', 'searchBills');
        throwIfNullOrUndefined(dateto, 'dateto', 'searchBills');
        throwIfNullOrUndefined(billItemsDTO, 'billItemsDTO', 'searchBills');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'datefrom': datefrom,
            'dateto': dateto,
        };

        return this.request<Array<BillDTO>>({
            url: '/bills/search/by/item',
            method: 'POST',
            headers,
            query,
            body: billItemsDTO,
        }, opts?.responseOpts);
    };

    /**
     */
    searchBills1({ datefrom, dateto, patientCode }: SearchBills1Request): Observable<Array<BillDTO>>
    searchBills1({ datefrom, dateto, patientCode }: SearchBills1Request, opts?: OperationOpts): Observable<RawAjaxResponse<Array<BillDTO>>>
    searchBills1({ datefrom, dateto, patientCode }: SearchBills1Request, opts?: OperationOpts): Observable<Array<BillDTO> | RawAjaxResponse<Array<BillDTO>>> {
        throwIfNullOrUndefined(datefrom, 'datefrom', 'searchBills1');
        throwIfNullOrUndefined(dateto, 'dateto', 'searchBills1');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'datefrom': datefrom,
            'dateto': dateto,
        };

        if (patientCode != null) { query['patient_code'] = patientCode; }

        return this.request<Array<BillDTO>>({
            url: '/bills',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    searchBillsByPayments({ billPaymentsDTO }: SearchBillsByPaymentsRequest): Observable<Array<BillDTO>>
    searchBillsByPayments({ billPaymentsDTO }: SearchBillsByPaymentsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<BillDTO>>>
    searchBillsByPayments({ billPaymentsDTO }: SearchBillsByPaymentsRequest, opts?: OperationOpts): Observable<Array<BillDTO> | RawAjaxResponse<Array<BillDTO>>> {
        throwIfNullOrUndefined(billPaymentsDTO, 'billPaymentsDTO', 'searchBillsByPayments');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<BillDTO>>({
            url: '/bills/search/by/payments',
            method: 'POST',
            headers,
            body: billPaymentsDTO,
        }, opts?.responseOpts);
    };

    /**
     */
    searchBillsPayments({ datefrom, dateto, patientCode }: SearchBillsPaymentsRequest): Observable<Array<BillPaymentsDTO>>
    searchBillsPayments({ datefrom, dateto, patientCode }: SearchBillsPaymentsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<BillPaymentsDTO>>>
    searchBillsPayments({ datefrom, dateto, patientCode }: SearchBillsPaymentsRequest, opts?: OperationOpts): Observable<Array<BillPaymentsDTO> | RawAjaxResponse<Array<BillPaymentsDTO>>> {
        throwIfNullOrUndefined(datefrom, 'datefrom', 'searchBillsPayments');
        throwIfNullOrUndefined(dateto, 'dateto', 'searchBillsPayments');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'datefrom': datefrom,
            'dateto': dateto,
        };

        if (patientCode != null) { query['patient_code'] = patientCode; }

        return this.request<Array<BillPaymentsDTO>>({
            url: '/bills/payments',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    updateBill({ id, fullBillDTO }: UpdateBillRequest): Observable<FullBillDTO>
    updateBill({ id, fullBillDTO }: UpdateBillRequest, opts?: OperationOpts): Observable<RawAjaxResponse<FullBillDTO>>
    updateBill({ id, fullBillDTO }: UpdateBillRequest, opts?: OperationOpts): Observable<FullBillDTO | RawAjaxResponse<FullBillDTO>> {
        throwIfNullOrUndefined(id, 'id', 'updateBill');
        throwIfNullOrUndefined(fullBillDTO, 'fullBillDTO', 'updateBill');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<FullBillDTO>({
            url: '/bills/{id}'.replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: fullBillDTO,
        }, opts?.responseOpts);
    };

}
