// tslint:disable
/**
 * OH 2.0 Api Documentation
 * OH 2.0 Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    WardDTO,
} from '../models';

export interface CheckWardCodeRequest {
    code: string;
}

export interface CheckWardMaternityCodeRequest {
    createIfNotExist: boolean;
}

export interface DeleteWardRequest {
    code: string;
}

export interface GetCurrentOccupationRequest {
    code: string;
}

export interface NewWardRequest {
    wardDTO: WardDTO;
}

export interface UpdateWardRequest {
    wardDTO: WardDTO;
}

/**
 * no description
 */
export class WardsApi extends BaseAPI {

    /**
     */
    checkWardCode({ code }: CheckWardCodeRequest): Observable<boolean>
    checkWardCode({ code }: CheckWardCodeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<boolean>>
    checkWardCode({ code }: CheckWardCodeRequest, opts?: OperationOpts): Observable<boolean | RawAjaxResponse<boolean>> {
        throwIfNullOrUndefined(code, 'code', 'checkWardCode');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<boolean>({
            url: '/wards/check/{code}'.replace('{code}', encodeURI(code)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    checkWardMaternityCode({ createIfNotExist }: CheckWardMaternityCodeRequest): Observable<boolean>
    checkWardMaternityCode({ createIfNotExist }: CheckWardMaternityCodeRequest, opts?: OperationOpts): Observable<RawAjaxResponse<boolean>>
    checkWardMaternityCode({ createIfNotExist }: CheckWardMaternityCodeRequest, opts?: OperationOpts): Observable<boolean | RawAjaxResponse<boolean>> {
        throwIfNullOrUndefined(createIfNotExist, 'createIfNotExist', 'checkWardMaternityCode');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<boolean>({
            url: '/wards/check/maternity/{createIfNotExist}'.replace('{createIfNotExist}', encodeURI(createIfNotExist)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    deleteWard({ code }: DeleteWardRequest): Observable<boolean>
    deleteWard({ code }: DeleteWardRequest, opts?: OperationOpts): Observable<RawAjaxResponse<boolean>>
    deleteWard({ code }: DeleteWardRequest, opts?: OperationOpts): Observable<boolean | RawAjaxResponse<boolean>> {
        throwIfNullOrUndefined(code, 'code', 'deleteWard');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<boolean>({
            url: '/wards/{code}'.replace('{code}', encodeURI(code)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getCurrentOccupation({ code }: GetCurrentOccupationRequest): Observable<number>
    getCurrentOccupation({ code }: GetCurrentOccupationRequest, opts?: OperationOpts): Observable<RawAjaxResponse<number>>
    getCurrentOccupation({ code }: GetCurrentOccupationRequest, opts?: OperationOpts): Observable<number | RawAjaxResponse<number>> {
        throwIfNullOrUndefined(code, 'code', 'getCurrentOccupation');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<number>({
            url: '/wards/occupation/{code}'.replace('{code}', encodeURI(code)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getWards(): Observable<Array<WardDTO>>
    getWards(opts?: OperationOpts): Observable<RawAjaxResponse<Array<WardDTO>>>
    getWards(opts?: OperationOpts): Observable<Array<WardDTO> | RawAjaxResponse<Array<WardDTO>>> {
        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<WardDTO>>({
            url: '/wards',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getWardsNoMaternity(): Observable<Array<WardDTO>>
    getWardsNoMaternity(opts?: OperationOpts): Observable<RawAjaxResponse<Array<WardDTO>>>
    getWardsNoMaternity(opts?: OperationOpts): Observable<Array<WardDTO> | RawAjaxResponse<Array<WardDTO>>> {
        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<WardDTO>>({
            url: '/wardsNoMaternity',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    newWard({ wardDTO }: NewWardRequest): Observable<WardDTO>
    newWard({ wardDTO }: NewWardRequest, opts?: OperationOpts): Observable<RawAjaxResponse<WardDTO>>
    newWard({ wardDTO }: NewWardRequest, opts?: OperationOpts): Observable<WardDTO | RawAjaxResponse<WardDTO>> {
        throwIfNullOrUndefined(wardDTO, 'wardDTO', 'newWard');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<WardDTO>({
            url: '/wards',
            method: 'POST',
            headers,
            body: wardDTO,
        }, opts?.responseOpts);
    };

    /**
     */
    updateWard({ wardDTO }: UpdateWardRequest): Observable<WardDTO>
    updateWard({ wardDTO }: UpdateWardRequest, opts?: OperationOpts): Observable<RawAjaxResponse<WardDTO>>
    updateWard({ wardDTO }: UpdateWardRequest, opts?: OperationOpts): Observable<WardDTO | RawAjaxResponse<WardDTO>> {
        throwIfNullOrUndefined(wardDTO, 'wardDTO', 'updateWard');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<WardDTO>({
            url: '/wards',
            method: 'PUT',
            headers,
            body: wardDTO,
        }, opts?.responseOpts);
    };

}
